import React from "react"

// Styles
import * as styles from "./CollectionEMuseumCTA.module.scss"

const CollectionEMuseumCTA = (props) => (

    <div className={styles.CollectionEMuseumCTA}>
        {/* <a href="{props.url}"> */}
            <div className={styles.textContent}>
                {props.title && 
                    <h3 dangerouslySetInnerHTML={{__html:props.title}}></h3>
                }
                {props.description &&
                    <div className={styles.description} dangerouslySetInnerHTML={{__html:props.description}}></div>
                }
                {props.link &&
                    <a href={props.link.url}>{props.buttonText}</a>
                }
            </div>
        {/* </a> */}
    </div>

)

export default CollectionEMuseumCTA