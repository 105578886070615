import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getCollectionPreview as PREVIEW_QUERY } from "../preview-queries/collectionPreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import Sidebar from "../components/Shared/Sidebar"
import Article from "../components/Shared/Article"
import LeaderList from "../components/Shared/LeaderList"
import Leader from "../components/Shared/Leader"
import ContentWithSidebar from "../components/Shared/ContentWithSidebar"
import Content from "../components/Shared/Content"
import HeroAreaLarge from "../components/Shared/HeroAreaLarge"
import SectionTitle from "../components/Shared/SectionTitle"
import CollectionEMuseumCTA from "../components/Collection/CollectionEMuseumCTA"
import { venueForPage } from "../helpers/venueForPage"
import SectionBlurb from "../components/Shared/SectionBlurb"
import ArticleHeader from "../components/Shared/ArticleHeader"
import { ResponsiveContext } from "grommet"
import ShareLinks from "../components/Shared/ShareLinks"
import CTA from "../components/Shared/CTA"
import PersonCTA from "../components/Shared/PersonCTA"
import SEOSettings from "../components/Shared/SEOSettings"

const CollectionTemplate = (props) => {
    const collection = getContent(props, "collection")
    const venue = venueForPage({ page: collection, menus: props.data.menus })

    return (
        <Layout venue={venue} title={collection.title}>
            <SEOSettings entity={collection} />
            <HeroAreaLarge
                superTitle="Collection"
                title={collection.title}
                introduction={collection.page_introduction.introduction}
                image={collection.image.image}
            />

            <Main>
                {collection?.subTitle && <SectionTitle>{collection.sub_title.subTitle}</SectionTitle>}

                {collection.collection_fields.collectionIntroduction && (
                    <SectionBlurb>
                        <p>{collection.collection_fields.collectionIntroduction}</p>
                    </SectionBlurb>
                )}

                {collection.collection_fields.highlightedObjects && collection.collection_fields.highlightedObjectsTitle && (
                    <SectionTitle>{collection.collection_fields.highlightedObjectsTitle}</SectionTitle>
                )}

                {collection.collection_fields.highlightedObjects && (
                    <LeaderList layoutType="twoColumnAllStacked">
                        {collection.collection_fields.highlightedObjects.map((object, index) => (
                            <Leader key={`object-${index}`} {...object} />
                        ))}
                    </LeaderList>
                )}

                <SectionTitle>About the collection</SectionTitle>

                <ContentWithSidebar>
                    <Content>
                        <Article>
                            <div dangerouslySetInnerHTML={{ __html: collection.content }}></div>
                        </Article>
                    </Content>

                    <Sidebar>
                        {collection.collection_fields.emuseumCta &&
                            collection.collection_fields.emuseumCta.title &&
                            collection.collection_fields.emuseumCta.showEmuseumCta && <CollectionEMuseumCTA {...collection.collection_fields.emuseumCta} />}
                        {collection.sidebar_calls.sidebarCtas && collection.sidebar_calls.sidebarCtas.map((cta) => <CTA key={cta.id} {...cta} />)}
                        {collection.person_ctas.people && collection.person_ctas.people.map((person) => <PersonCTA key={person.id} {...person} />)}
                    </Sidebar>
                </ContentWithSidebar>
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query CollectionQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        collection: wpCollection(databaseId: { eq: $databaseId }) {
            title
            ...CollectionContent
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                  sourceUrl
                }
            }
        
            sidebar_calls {
                sidebarCtas {
                    ... on WpCallstoAction {
                        id
                        title
                        cta_fields {
                            link {
                                title
                                target
                                url
                            }
                            description
                            image {
                                sourceUrl
                                title
                            }
                        }
                    }
                }
            }
            person_ctas {
                people {
                    ... on WpPerson {
                        id
                        title
                        person_fields {
                            ctaDescription
                            email: emailAddress
                            role
                            phone: telephoneNumber
                            image {
                                sourceUrl
                                title
                            }
                        }
                    }
                }
            }
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(CollectionTemplate)
