import React, { useEffect } from "react"

// Styles
import * as styles from "./SectionTitle.module.scss"

const SectionTitle = ({ children }) => {
  return <div className={styles.SectionTitle}>{children}</div>
}

export default SectionTitle
