import gql from "graphql-tag"

export const getCollectionPreview = gql`
    query EventPreviewQuery($id: ID!, $revision_id: Int!) {
        collection(id: $id, idType: DATABASE_ID) {
            revisions(where: { id: $revision_id }) {
                edges {
                    node {
                        id
                        title
                        content
                        link
                        image: hero_fields {
                            image {
                                sourceUrl
                                altText
                                title
                            }
                        }
                        page_introduction {
                            introduction
                        }
                        sub_title {
                            subTitle
                        }
                        sidebar_calls {
                            sidebarCtas {
                                ... on CTA {
                                    id
                                    title
                                    cta_fields {
                                        link {
                                            title
                                            target
                                            url
                                        }
                                        description
                                        image {
                                            sourceUrl
                                            title
                                        }
                                    }
                                }
                            }
                        }
                        person_ctas {
                            people {
                                ... on Person {
                                    id
                                    title
                                    person_fields {
                                        ctaDescription
                                        email: emailAddress
                                        role
                                        phone: telephoneNumber
                                        image {
                                            sourceUrl
                                            title
                                        }
                                    }
                                }
                            }
                        }
                        collection_fields {
                            highlightedObjectsTitle
                            emuseumCta {
                                title
                                description
                                link {
                                    url
                                    title
                                }
                                buttonText
                            }
                            highlightedObjects {
                                title
                                description
                                image {
                                    sourceUrl
                                    title
                                }
                                link {
                                    url
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
