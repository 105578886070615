import React from "react"

// Styles
import * as styles from "./HeroAreaLarge.module.scss"

// Components
import PlaceholderImage from "./PlaceHolderImage"
import ParallaxItem from "./ParallaxItem"
import Image from "./Image";
import {imageSizes} from "../../helpers/imgix";

const HeroAreaLarge = props => (
  <div className={styles.HeroAreaLarge}>
    <div className={styles.image}>
      {props.image &&
        <ParallaxItem>
          <Image url={props.image.sourceUrl} alt={props.image.title} sizes={imageSizes.hero} />
        </ParallaxItem>
      }
    </div>

        <div className={styles.textContent}>
            {props.superTitle && <span className={styles.superTitle} dangerouslySetInnerHTML={{ __html: props.superTitle }}></span>}
            <h1 dangerouslySetInnerHTML={{ __html: props.title }}></h1>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: props.introduction }}></div>
        </div>
        {props.backlink && (
            <div className={styles.seeAll}>
                <a href={props.backlink}>{props.backtext}</a>
            </div>
        )}
    </div>
)

export default HeroAreaLarge
