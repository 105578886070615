import React from "react"

// Styles
import * as styles from "./SectionBlurb.module.scss"

const SectionBlurb = ({ children }) => (
  <div className={styles.SectionBlurb}>{children}</div>
)

export default SectionBlurb
